@tailwind base;
@tailwind components;
@tailwind utilities;



body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@media (max-width: 768px) {
  header img {
    height: 12px; /* smaller logo on mobile */
  }

  .header nav ul {
    display: none; /* hide the nav menu on smaller screens */
  }

  .header .mobile-menu-icon {
    display: block; /* show mobile menu icon */
  }
}


/* Add this inside your main CSS file */
@keyframes glow {
  0%, 100% {
    box-shadow: 0 0 15px #ffc107, 0 0 30px #ffc107, 0 0 45px #ffc107, 0 0 60px #ffc107;
  }
  50% {
    box-shadow: 0 0 20px #ffecb3, 0 0 40px #ffecb3, 0 0 60px #ffecb3, 0 0 80px #ffecb3;
  }
}

.glow-on-hover {
    animation: glow 1.5s infinite alternate;
}
